module.exports = [{
      plugin: require('../plugins/gatsby-plugin-map-background/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Helsinki Fest Art Gift","short_name":"Art Gift","start_url":"/","background_color":"#000000","theme_color":"#ffffff","display":"minimal-ui","icon":"src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"63417426d1fcbfd3c5ad2ec589d869d5"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/frontend/src/intl","languages":["is","en"],"defaultLanguage":"is","redirect":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
